import { Component, Inject, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-upload/events/select-event';
import { JobSetupService } from 'src/app/services/job-setup.service';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Expensecode, JobSetup, Timecode, Trackedtables, TriggerAndJobConfigDetails, TriggerEventConfigDetails } from 'src/app/models/config.model';
import { Jobfrequency } from 'src/app/interfaces/jobfrequency';
import { JobName } from 'src/app/interfaces/jobName';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { DateInputSize, DateInputRounded, DateInputFillMode } from "@progress/kendo-angular-dateinputs";
import { PanelBarCollapseEvent, PanelBarExpandEvent, PanelBarExpandMode, PanelBarStateChangeEvent } from '@progress/kendo-angular-layout';
import { TriggerEventService } from 'src/app/services/trigger-event.service';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { TriggerAndJobService } from 'src/app/services/trigger-and-job.service';


@Component({
  selector: 'app-job-setup',
  templateUrl: './job-setup.component.html',
  styleUrls: ['./job-setup.component.scss']
})
export class JobSetupComponent {
  public triggerAndJobApiData: any;
  // public disableJobSetupTab: boolean;
  public showAlert: boolean = true;
  public date: Date = new Date();
  public selectedFrequency: string | undefined;
  public autoCorrect = true;
  public minDate = 1;
  public maxDate = 31;
  public minHour = 1;
  public minRetry = 0;
  public maxRetry = 3;
  public maxHour = 23;
  public min = 0;
  public max = 59;
  public format = "MM/dd/yyyy HH:mm";
  public selDate: Date = new Date(2019, 5, 1, 21, 45);
  public selectedJobFrequencyValue: Number = 0;
  public selectedDayOfWeekMonthValue: Number = 0;
  //public frequencySource: Jobfrequency[] = [];
  //public frequencies: Jobfrequency[] = [];
  @Input() frequecyMasterData: Jobfrequency[] = [];


  public filteredFrequencyData: Jobfrequency[] = [];
  public selTriggerType: string;
  public expandMode: PanelBarExpandMode = 1;
  public height = 320;
  public expandedState = true;

  public weekDaySource: Array<{ text: string; value: number }> = [
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 },
    { text: "Thursday", value: 4 },
    { text: "Friday", value: 5 },
    { text: "Saturday", value: 6 },
    { text: "Sunday", value: 7 },
  ];

  public weekDays: Array<{ text: string; value: number }>;

  public monthDaySource: Array<{ text: string; value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 },
    { text: "13", value: 13 },
    { text: "14", value: 14 },
    { text: "15", value: 15 },
    { text: "16", value: 16 },
    { text: "17", value: 17 },
    { text: "18", value: 18 },
    { text: "19", value: 19 },
    { text: "20", value: 20 },
    { text: "21", value: 21 },
    { text: "22", value: 22 },
    { text: "23", value: 23 },
    { text: "24", value: 24 },
    { text: "25", value: 25 },
    { text: "26", value: 26 },
    { text: "27", value: 27 },
    { text: "28", value: 28 },
    { text: "Last Day Of Month", value: -1 },

  ];
  public monthDays: Array<{ text: string; value: number }>;
  public selectedSourceSystem: string | undefined;
  public selectedClientName: string | null;
  public selectedTriggerType: string | null;
  public addmore: boolean = false;
  // public addJob: boolean = false;

  @Input()
  clientConfigurationId: number = 0;
  clientJobSetupId: number = 0;
  clientId: number = 0;
  clientName: string;
  sourceSystem: string;
  orgCode: string;

  public isEditMode: boolean = false;
  public isJobSetupDisabled: boolean = false;
  public rounded: DateInputRounded = "small";
  public fillMode: DateInputFillMode = "flat";
  public size: DateInputSize = "small";

  public jobfreq: any = {};

  public jobSetupForm: FormGroup;
  triggerJobForm: FormGroup;
  LoggedInName: string = "";
  public triggerEventForm: FormGroup;
  public trigtype: any = {};

  public triggerEventApiData: TriggerEventConfigDetails;
  public disableTriggerEventTab: boolean;

  @Input()
  // clientConfigurationId: number;
  clientTriggerEventConfigurationId: number = 0;
  // clientName: string;
  // sourceSystem: string;
  // orgCode: string;

  // public isEditMode: boolean = false;
  public selectedTrackedTables: any[] = [];
  public selectedTimeCodes: any[] = [];
  public selectedExpenseCode: any[] = [];
  public triggerTypeData: Triggertype[] = [];
  public triggerType: Triggertype[] = [];
  public selectedExpenseCodeValue: Number = 0;
  public selectedTriggerTypeValue: Number = 0;
  public selectedTimeCodeValue: Number = 0;

  //public tableToTrackData: Trackedtables[] = [];
  // public tableToBeTrack: Trackedtables[] = [];

  //public timeCodeData: Timecode[] = [];
  public timeCode: Timecode[] = [];

  public expenseCodeData: Expensecode[] = [];
  public expenseCode: Expensecode[] = [];

  // public trigtype: any = {};

  // public triggerEventForm: FormGroup;

  // public selectedSourceSystem: string | undefined;
  // public selectedClientName: string | null;
  // public selectedTriggerType: string | null;
  // LoggedInName: string;
  @Input() allTrackedMasterData: any = [];
  @Input() expenseCodeMasterData: any = [];
  @Input() timeCodeMasterData: any = [];
  public selectedItems: Trackedtables[] = [];
  items = [];
  filteredAllTrackedData: any = [];
  filteredTimeCodeMasterData: any = [];
  @Input() rootFormGroup: any;
  // filteredExpenseCodeMasterData:any = [];
  get getFormControl() { return this.triggerJobForm.controls; }
  public trackedtablesStr: string = "";
  public timeCodeStr: string = "";
  @ViewChild('TT') TT!: ElementRef;
  addmoreshow: boolean = false;
  lastExecutedValue = "";
  flagforjob: boolean = false;
  jobnamedisable: boolean = false;



  constructor(@Inject(TriggerEventService) private triggerEventService: TriggerEventService, @Inject(TriggerAndJobService) private triggerAndJobService: TriggerAndJobService, @Inject(MasterdataService) private masterdataService: MasterdataService, @Inject(JobSetupService) private jobSetupService: JobSetupService, private fb: FormBuilder, private dialogService: DialogService) {
    // this.masterdataService.getAllJobfrequency().subscribe(data => {
    //   this.frequencySource = data;
    //   this.frequencies = data;
    // });
    this.masterdataService.getAllTriggerType().subscribe(data => {
      this.triggerTypeData = data.filter(s => s.isActive == true);
    });
    this.clientId = Number(localStorage.getItem('clientId'));
    this.weekDays = this.weekDaySource.slice();
    this.monthDays = this.monthDaySource.slice();

  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    this.clientName = localStorage.getItem('clientName');
    this.sourceSystem = localStorage.getItem('sourceSystem');
    this.orgCode = localStorage.getItem('orgCode');
    // if (!this.clientName || !this.sourceSystem || !this.orgCode) {
    //   this.disableJobSetupTab = true;
    //   this.showConfirmation("Tab disabled", "Please configure client first");
    // }
  }


  ngOnInit(): void {

    this.triggerJobForm = this.fb.group({
      triggerJobArr: this.fb.array([

        // this.newTrigerJob()
        //  this.getParticipant()
        //  console.log()

      ])
    });



    console.log(this.triggerEventForm)
    this.filteredFrequencyData = this.frequecyMasterData;
    this.clientConfigurationId = Number(localStorage.getItem('clientConfigurationId'));
    if (this.clientConfigurationId > 0) {
      this.addmoreshow = true;
      this.getJobSetupDetails(this.clientConfigurationId);
      // this.getTriggerEventData(this.clientConfigurationId);
      if (this.clientJobSetupId > 0) {
        this.isEditMode = true;

      }
    }
    this.filteredAllTrackedData = this.allTrackedMasterData;
    this.filteredTimeCodeMasterData = this.timeCodeMasterData;
    //this.filteredExpenseCodeMasterData = this.expenseCodeMasterData;
    this.clientConfigurationId = Number(localStorage.getItem('clientConfigurationId'));
    this.selectedSourceSystem = localStorage.getItem('sourceSystem');
    //this.selectedItems = [this.allTrackedMasterData[0]];


  }
  newTrigerJob(): FormGroup {
    return this.fb.group({
      triggerType: new FormControl([Validators.required]),
      timeCodeToBeTracked: new FormControl(),
      //expenseCodeToBeTracked: new FormControl(),
      tableToTrack: new FormControl(),
      jobFrequencyId: new FormControl([Validators.required]),
      jobDuration: new FormControl(this.jobfreq.jobDuration),
      isActive: new FormControl(true, [Validators.required]),
      dayOfWeekMonth: new FormControl(),
      jobName: new FormControl(this.jobfreq.jobName, [Validators.required]),
      jobStartTime: new FormControl(this.jobfreq.jobStartTime, [Validators.required]),
      retryJobCount: new FormControl(),
      lastExecuted: new FormControl()
    })
  }

  get triggerJob(): FormArray {
    // return this.triggerJobForm.get('triggerJobArr') as FormArray;
    return <FormArray>this.triggerJobForm.get('triggerJobArr')
  }
  addApiAndJobDetails(data: any) {
    console.log(data);
    if (this.triggerJobForm.value.triggerJobArr) {
      this.triggerJob.push(this.createTriggerJobGroup(data));
    }

  }
  createTriggerJobGroup(data) {
    let triggerTypeObj = { id: data.triggerTypeId, type: data.triggerType }
    let getFrequencyObj = { id: data.jobFrequencyId, name: this.frequecyMasterData.find(item => item.id === data.jobFrequencyId) }
    let tableTrackData = [];
    tableTrackData = (data.trackedTablesId != null) ? this.allTrackedMasterData?.filter(item => data.trackedTablesId?.split(',').includes(item.id.toString())) : '';
    if (data.trackedTablesId) {
      this.selectedTrackedTables = this.allTrackedMasterData?.filter(item => data.trackedTablesId?.split(',').includes(item.id.toString()));
    }
    if (tableTrackData.length < 1) {
      tableTrackData = [this.allTrackedMasterData[0]];
    }
    let timeCodeData = [];
    timeCodeData = (data.timeCodeId != null) ? this.timeCodeMasterData?.filter(item => String(data.timeCodeId)?.split(',').includes(item.id.toString())) : '';

    if (data.timeCodeId) {
      this.selectedTimeCodes = this.timeCodeMasterData?.filter(item => String(data.timeCodeId)?.split(',').includes(item.id.toString()));
    }
    let dayOfWeekMonthObj = data.jobFrequencyId === 4 ? this.weekDays.find(item => item.value === data.dayOfWeekMonth) : data.dayOfWeekMonth ||
      data.jobFrequencyId === 5 ? this.monthDays.find(item => item.value === data.dayOfWeekMonth) : data.dayOfWeekMonth

    return this.fb.group({

      triggerType: [triggerTypeObj, [Validators.required]],
      timeCodeToBeTracked: [timeCodeData],
      tableToTrack: [tableTrackData, [Validators.required]],
      jobFrequencyId: [getFrequencyObj, [Validators.required]],
      jobDuration: [data.jobDuration],
      isActive: [data.isActive, [Validators.required]],
      dayOfWeekMonth: [dayOfWeekMonthObj],
      jobName: [{ value: data.jobName, disabled: true }],
      jobStartTime: [(data.jobStartTime == "Invalid Date" || data.jobStartTime == null) ? '' : new Date(data.jobStartTime?.toString())
        , [Validators.required]],
      retryJobCount: [data.retryJobCount],
      lastExecuted: [data.lastExecuted]

    })
  }

  addTriggerJob() {

    this.triggerJob.push(this.newTrigerJob());
  }




  public isJobNameDisabled(jobName: any): void {
    if (jobName !== '' || jobName == undefined) {
      this.isJobSetupDisabled = true;
    }
    else {
      this.isJobSetupDisabled = false;
    }
  }

  public frequencySelectionChange(value: any): void {
    this.selectedFrequency = value.frequency;
  }


  frquencyFilter(value: any) {
    // this.frequencySource = this.frequencies;
    // this.frequencySource = this.frequencies.filter(
    //   (s) => s.frequency.toLowerCase().indexOf(value.toLowerCase()) !== -1
    // );
    this.filteredFrequencyData = this.frequecyMasterData;
    this.filteredFrequencyData = this.frequecyMasterData.filter(
      (s) => s.frequency.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  daysFilter(value: any) {
    this.weekDays = this.weekDaySource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  monthFilter(value: any) {
    this.monthDays = this.monthDaySource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }


  triggerjobData: any = [];
  public getJobSetupDetails(clientConfigurationId: number) {
    this.triggerAndJobService.GetClientTriggerEventAndJobSetupDetails(clientConfigurationId)
      .subscribe(triggerAndJobApiData => {

        // this.triggerAndJobApiData = data;
        console.log(triggerAndJobApiData)
        if (triggerAndJobApiData.length > 0) {
          triggerAndJobApiData.forEach(apidata => {
            this.addApiAndJobDetails(apidata)
            if (apidata.clientJobSetupId > 0) {
              this.isEditMode = true;

            }

            //  this.lastExecutedValue= apidata.lastExecuted ;
          })
        }
        else {
          this.triggerJobForm = this.fb.group({
            triggerJobArr: this.fb.array([

              this.newTrigerJob()
              //  this.getParticipant()
              //  console.log()

            ])
          });
        }
        // console.log(typeof(triggerAndJobApiData));
        // this.triggerjobData.push(triggerAndJobApiData);
        // console.log(this.triggerjobData);
        // for(let i = 0; i<= this.triggerAndJobApiData.length; i++){
        if (triggerAndJobApiData) {
          // let triggerTypeObj = {};
          let tableTrackData = [];
          let timeCodeData = [];
          // let getFrequency = this.frequecyMasterData.find(item => item.id === this.triggerAndJobApiData[i].jobFrequencyId);
          // this.selectedFrequency = this.frequecyMasterData.find(item => item.id === this.triggerAndJobApiData[i].jobFrequencyId)?.frequency;
          this.clientJobSetupId = triggerAndJobApiData.clientJobSetupId;
          tableTrackData = (triggerAndJobApiData.trackedTablesId != null) ? this.allTrackedMasterData?.filter(item => triggerAndJobApiData.trackedTablesId?.split(',').includes(item.id.toString())) : '';
          timeCodeData = (triggerAndJobApiData.timeCodeId != null) ? this.timeCodeMasterData?.filter(item => String(triggerAndJobApiData.timeCodeId)?.split(',').includes(item.id.toString())) : '';
          // expenseCodeData = (triggerEventApiData.expenseCodeId != null) ? this.expenseCodeMasterData?.filter(item => String(triggerEventApiData.expenseCodeId)?.split(',').includes(item.id.toString())) : '';
          if (tableTrackData.length < 1) {
            tableTrackData = [this.allTrackedMasterData[0]];
          }


          // if (this.triggerAndJobApiData[i].triggerTypeId != 0) {
          //   this.selectedTriggerType = this.triggerAndJobApiData[i].triggerType;
          //   triggerTypeObj =  {id:this.triggerAndJobApiData[i].triggerTypeId,type:this.triggerAndJobApiData[i].triggerType}
          //   //console.log("triggerTypeObj",triggerTypeObj);
          // }else{
          //   triggerTypeObj = null;
          // }

          this.selectedClientName = localStorage.getItem('clientName')?.toString();
          this.clientTriggerEventConfigurationId = triggerAndJobApiData.clientTriggerEventConfigurationId;
          if (triggerAndJobApiData.trackedTablesId) {
            this.selectedTrackedTables = this.allTrackedMasterData?.filter(item => triggerAndJobApiData.trackedTablesId?.split(',').includes(item.id.toString()));
          }
          if (triggerAndJobApiData.timeCodeId) {
            this.selectedTimeCodes = this.timeCodeMasterData?.filter(item => String(triggerAndJobApiData.timeCodeId)?.split(',').includes(item.id.toString()));
          }
          // if(triggerEventApiData.expenseCodeId != 0){
          //   this.selectedExpenseCode = this.expenseCodeMasterData?.filter(item => String(triggerEventApiData.expenseCodeId)?.split(',').includes(item.id.toString()));
          // }


          console.log(this.triggerJobForm)
          console.log(this.triggerJobForm.controls);
          // this.triggerJobForm.setControl('triggerJobArr', this.setExistingTriggerJob(triggerAndJobApiData));


          // this.triggerJobForm.patchValue({

          // id: this.triggerAndJobApiData[i].clientTriggerEventConfigurationId,
          // clientId: Number(localStorage.getItem('clientId')),
          // clientName: localStorage.getItem('clientName')?.toString(),
          // triggerType:this.triggerAndJobApiData[i].triggerType,
          // sourceSystem: localStorage.getItem('sourceSystem')?.toString(),
          // //timeCodeToBeTracked: this.timeCodeMasterData.find(item => item.id === triggerEventApiData.timeCodeId),
          // //expenseCodeToBeTracked: this.expenseCodeMasterData.find(item => item.id === triggerEventApiData.expenseCodeId),
          //  timeCodeToBeTracked:timeCodeData,
          // //expenseCodeToBeTracked:expenseCodeData,
          //   tableToTrack: tableTrackData,
          //   clientJobSetupId: this.triggerAndJobApiData[i].clientJobSetupId,
          //   clientConfigurationId: Number(localStorage.getItem('clientConfigurationId')),
          //   //clientId: Number(localStorage.getItem('clientId')),
          //   jobName:this.triggerAndJobApiData[i].jobName,
          //  dayOfWeekMonth:this.triggerAndJobApiData.jobFrequencyId === 4 ? this.weekDays.find(item => item.value === this.triggerAndJobApiData.dayOfWeekMonth) : this.triggerAndJobApiData.dayOfWeekMonth ||
          //  this.triggerAndJobApiData.jobFrequencyId === 5 ? this.monthDays.find(item => item.value === this.triggerAndJobApiData.dayOfWeekMonth): this.triggerAndJobApiData.dayOfWeekMonth,
          //   isActive:this.triggerAndJobApiData[i].isActive,
          //   jobDuration: this.triggerAndJobApiData[i].jobDuration,
          //   jobFrequencyId: getFrequency,
          //   jobStartTime: (this.triggerAndJobApiData[i].jobStartTime == "Invalid Date" || this.triggerAndJobApiData[i].jobStartTime == null) ? '' : new Date(this.triggerAndJobApiData[i].jobStartTime?.toString()),
          //   retryJobCount: this.triggerAndJobApiData[i].retryJobCount,
          // });
          // this.selDate = new Date(this.triggerAndJobApiData[i].jobStartTime?.toString());
          // this.isJobNameDisabled(this.triggerAndJobApiData[i].jobName);
        }
        // this.selTriggerType = localStorage.getItem('triggerType');
        // }
      })

  }



  // setExistingTriggerJob(triggJob: TriggerAndJobConfigDetails[]): FormArray {
  //   const formArray = new FormArray([]);
  //   triggJob.forEach(s => {
  //     formArray.push(this.fb.group({
  //       clientJobSetupId: s.clientJobSetupId,
  //       clientConfigurationId: s.clientConfigurationId,
  //       clientTriggerEventConfigurationId: s.clientTriggerEventConfigurationId,
  //       clientId: s.clientId,
  //       triggerType: s.triggerType,
  //       jobName: s.jobName,
  //       isActive: s.isActive,
  //       retryJobCount : s.retryJobCount,
  //       jobDuration : s.jobDuration,
  //       jobFrequencyId: s.jobFrequencyId,
  //       jobStartTime: s.jobStartTime,
  //       dayOfWeekMonth: s.dayOfWeekMonth,
  //       tableToTrack: s.trackedTableName,
  //       timeCodeToBeTracked: s.timeCode,

  //     }));
  //   });

  //   return formArray;
  // }

  public exit() {
    if (this.triggerJobForm.dirty) {
      this.showConfirmationOnBack("You have unsaved data on the page.Do you want to continue?");
    } else {
      window.location.reload();
    }
  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        console.log("action", result);
        window.location.reload();
      }
      this.result = JSON.stringify(result);
    });
  }

  public showConfirmationOnBack(dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Please confirm",
      content: dialogContent,
      actions: [{ text: "Yes" }, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        if (result.text == "Yes") {
          //this._location.back();
          window.location.reload();
        }
      }
      this.result = JSON.stringify(result);
    });
  }

  public showConfirmationvalidity(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        console.log("action", result);
        // window.location.reload();
      }
      this.result = JSON.stringify(result);
    });
  }

  disabled() {
    if (this.clientConfigurationId > 0) {
      if (this.clientJobSetupId > 0) {
        this.isEditMode = true;
      }
    }
    if (this.isEditMode) {
      if (!this.triggerJobForm.valid || !this.triggerJobForm.dirty) {
        return true;
      }
    } else {
      if (!this.triggerJobForm.valid) {
        return true;
      }
    }
    return false;
  }

  public clientNameChange(value: any): void {
    this.selectedClientName = value.name
  }

  public triggerTypeFilter(value: string) {
    this.triggerTypeData = this.triggerType;
    this.triggerTypeData = this.triggerType.filter(
      (s) => s.type.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public triggerTypeSelectionChange(value: any): void {
    // for(let i = 0; i<=value.type;i++){
    this.selectedTriggerType = value.type;
    // }
    // localStorage.setItem('triggerType', this.selectedTriggerType);
  }

  public tableToTrackFilter(value: string) {
    // this.tableToTrackData = this.tableToBeTrack;
    // this.tableToTrackData = this.tableToBeTrack?.filter(
    //   (s) => s.tableName.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    // );
    //this.allTrackedMasterData = this.tableToBeTrack;
    this.filteredAllTrackedData = this.allTrackedMasterData?.filter(
      (s) => s.tableName.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public timeCodeFilter(value: string) {
    //this.timeCodeData = this.timeCode;
    this.filteredTimeCodeMasterData = this.timeCodeMasterData.filter(
      (s) => s.code.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  // public expenseCodeFilter(value: string) {
  //   //this.expenseCodeData = this.expenseCode;
  //   this.filteredExpenseCodeMasterData = this.expenseCodeMasterData.filter(
  //     (s) => s.code.toLowerCase().indexOf(value?.toLowerCase()) !== -1
  //   );
  // }





  // starting new formarray






  removeTriggerJob(triggerJobIndex: number) {
    this.triggerJob.removeAt(triggerJobIndex);
  }


  public getFormData(): any {
    this.LoggedInName = sessionStorage.getItem('username');

    let data: TriggerAndJobConfigDetails = new TriggerAndJobConfigDetails();
    const formValue = this.triggerJobForm.getRawValue();
    // localStorage.setItem('triggerType', formValue.triggerType.type);
    //if (formValue.clientTriggerEventConfigurationId > 0) {
    if (formValue.clientTriggerEventConfigurationId && formValue.clientJobSetupId > 0) {
      data.clientTriggerEventConfigurationId = formValue.clientTriggerEventConfigurationId;
      data.clientJobSetupId = formValue.clientJobSetupId;
    }
    if (Number(localStorage.getItem('clientConfigurationId')) <= 0) {
      this.clientConfigurationId = Number(localStorage.getItem('clientConfigurationId'));
    }
    data.clientConfigurationId = this.clientConfigurationId;

    let dataArr = [];
    formValue.triggerJobArr.forEach((element, index) => {
      dataArr.push(element);

      element.clientId = Number(localStorage.getItem('clientId'))
      element.clientConfigurationId = this.clientConfigurationId;

      element.jobName = element.jobName;

      element.jobDuration = element.jobDuration == null ? 0 : element.jobDuration;
      element.retryJobCount = element.retryJobCount;
      element.jobStartTime = element.jobStartTime ? element.jobStartTime.toLocaleString("en-us") : null;
      element.jobFrequencyId = element.jobFrequencyId.id;
      element.triggerTypeId = element.triggerType.id;
      // for(let i = 0; i<=formValue.triggerJobArr.length;i++){
      //   this.selectedTriggerType = element.triggerType.id;

      // }

      let trackedTableArr = [];
      for (let i = 0; i < element.tableToTrack.length; i++) {
        trackedTableArr.push(element.tableToTrack[i].id);
        console.log(element.tableToTrack[i].id);
      }
      this.trackedtablesStr = trackedTableArr.join(',');
      element.trackedTablesId = this.trackedtablesStr;
      delete (element.tableToTrack);
      if (formValue.triggerJobArr[index].triggerType.id == 1) {
        let timeCodeArr = [];
        for (let i = 0; i < element.timeCodeToBeTracked.length; i++) {
          timeCodeArr.push(element.timeCodeToBeTracked[i].id);
          console.log(element.timeCodeToBeTracked[i].id);
        }
        this.timeCodeStr = timeCodeArr.join(',');
        element.timeCodeId = this.timeCodeStr;

        // element.timeCodeId = element.timeCodeId[index].id.toString();
      }
      delete (element.timeCodeToBeTracked);
      // let dayofWeekOrMonth;
      if (formValue.triggerJobArr[index].jobFrequencyId == 4) {
        element.dayOfWeekMonth = element.dayOfWeekMonth.value;
      }
      else if (formValue.triggerJobArr[index].jobFrequencyId == 5) {
        element.dayOfWeekMonth = element.dayOfWeekMonth.value;
      }
      else {
        element.dayOfWeekMonth = 0;
      }
      element.dayOfWeekMonth = element.dayOfWeekMonth;
      element.isActive = element.isActive;
      if (+formValue.id > 0) {
        element.modifiedBy = this.LoggedInName;
      }
      else {
        element.createdBy = this.LoggedInName;
      }
      delete (element.triggerType);

      // dataArr.push(data)
      console.log(dataArr)
      // return dataArr;
    });

    console.log(data);
    console.log("formValue", formValue);

    return dataArr;

  };



  public createClientTriggerAndJob(data: any) {
    this.triggerAndJobService.createClientTriggerAndJob(data).subscribe((resp) => {
      // this.clientJobSetupId = resp.id;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Client Trigger & Job setup updated successfully");
        //need to fetch recently created data using resp.id which has value of clientConfigurationId
        //now but will need clientJobSetupId to fetch ClientJobSetup data instead clientConfigurationId
        this.clientConfigurationId = this.clientConfigurationId > 0 ? this.clientConfigurationId : Number(localStorage.getItem('clientConfigurationId'));
        // this.getJobSetupDetails(this.clientConfigurationId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("error1");
    });
  }

  public updateClientTriggerAndJob(data: any) {
    this.triggerAndJobService.updateClientTriggerAndJob(data).subscribe((resp) => {
      if (resp.isSuccess) {
        console.log("Client job setup updated successfully");

        this.showConfirmation("Success", "Client job setup updated successfully");
        this.clientConfigurationId = this.clientConfigurationId > 0 ? this.clientConfigurationId : Number(localStorage.getItem('clientConfigurationId'));
        // this.getJobSetupDetails(this.clientConfigurationId);
      } else {

        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }
  public saveTriggerAndJob(): void {
    let data = this.getFormData();
    console.log("data", data);

    // return;
    if (this.triggerJobForm.getRawValue().tableToTrack?.find(t => t.id == 1)) {
      this.showConfirmation("Error", "Please select Claim table from tracked tables");
    }
    if (!this.triggerJobForm.valid) {
      this.showConfirmationvalidity("Error", "All fields are mandatory")
    }
    else {
      if (this.clientTriggerEventConfigurationId && this.clientJobSetupId > 0) {

        this.updateClientTriggerAndJob(data);
      } else {
        this.createClientTriggerAndJob(data);
      }
    }
  }

  //triggerType


  public onChangeResponsibility(index: number): void {
    let rowControls: any = (<FormGroup>this.triggerJob.controls[index]).controls;
    let responsibilityPercentageControl = rowControls.timeCodeToBeTracked;


    if (this.isResponsibilityPercentageSelected(index)) {
      responsibilityPercentageControl.enable();
    } else {
      responsibilityPercentageControl.disable();
    }
  }
  public isResponsibilityPercentageSelected(index: number): boolean {
    return (!!this.triggerJob.value[index].triggerType) &&
      (this.triggerJob.value[index].triggerType.type === "Docket");
  }

  //jobfrequency
  public isMonthlySelected(index: number): boolean {
    return (!!this.triggerJob.value[index].jobFrequencyId) &&
      (this.triggerJob.value[index].jobFrequencyId.id === 5)
  }
  public isWeeklySelected(index: number): boolean {
    return (!!this.triggerJob.value[index].jobFrequencyId) &&
      (this.triggerJob.value[index].jobFrequencyId.id === 4)
  }
  public onFrequencyResponsibility(index: number): void {
    let rowControls: any = (<FormGroup>this.triggerJob.controls[index]).controls;
    let responsibilityControl = rowControls.dayOfWeekMonth;
    let frequencyControl = rowControls.jobDuration;



    // if (this.isMonthlySelected(index)) {
    //   responsibilityControl.enable();
    // } else {
    //   responsibilityControl.disable();
    // }
    // if (this.isMinuteSelected(index)) {
    //   frequencyControl.enable()
    // } else {
    //   frequencyControl.disable();
    // }
  }
  public isMinuteSelected(index): boolean {
    return (!!this.triggerJob.value[index].jobFrequencyId) &&

      (this.triggerJob.value[index].jobFrequencyId.id === 1);
  }
  public isHourlySelected(index): boolean {
    return (!!this.triggerJob.value[index].jobFrequencyId) &&

      (this.triggerJob.value[index].jobFrequencyId.id === 2);
  }
}
