<app-contentheader headerText="On Demand Job Processing"></app-contentheader>
<div class="card-box">
  <div *ngIf="!addDemand">
    <div [style.height.px]="50">
      <button _ngcontent-whw-c5 kendoButton size="medium" rounded="medium"
        style="color: #fff; background-color: #193971 !important" (click)="addOnDemandProcessing()">
        Add On Demand Job Processing
      </button>
    </div>
    <kendo-grid [kendoGridBinding]="onDemandGridView" [data]="onDemandGridView" [reorderable]="true" filterable="menu"
      cssClass="grid-height" [pageSize]="10" [pageable]="{
          pageSizes: pageSizes
        }" [sortable]="true" [groupable]="true" [reorderable]="true" [resizable]="true">
      <ng-template kendoGridToolbarTemplate>
        <input [style.width.px]="500" placeholder="Search in all columns..." kendoTextBox (input)="onFilter($event)" />
        <kendo-grid-spacer></kendo-grid-spacer>
        <button kendoGridExcelCommand type="button" icon="file-excel" title="Download Excel"></button>
        <button kendoGridPDFCommand type="button" icon="file-pdf" title="Download Pdf"></button>
        <kendo-grid-column-chooser></kendo-grid-column-chooser>
      </ng-template>
      <kendo-grid-column field="clientName" title="Client Name" [width]="130"></kendo-grid-column>
      <kendo-grid-column field="jobName" title="Job Name" [width]="130">
      </kendo-grid-column>
      <!-- <kendo-grid-column field="isRangeOfClaims" title="Is Range Of Claims" filter="boolean" [width]="220">
      </kendo-grid-column> -->
      <kendo-grid-column field="startClaimId" filter="numeric" format="000" title="Start ClaimId" [width]="110">
      </kendo-grid-column>
      <kendo-grid-column field="endClaimId" filter="numeric" format="000" title="End ClaimId" [width]="110">
      </kendo-grid-column>
      <kendo-grid-column field="noOfClaimsProcessed" title="Processed Claims" [width]="130">
      </kendo-grid-column>

      <kendo-grid-column field="status" title="Status" [width]="100">
      </kendo-grid-column>

      <kendo-grid-column field="isStoppedProcessing" title="Stop Processing Claims" [filterable]="false"
        [headerClass]="'headerCustomClass'" [width]="180"><ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton style="color: #fff; background-color: #193971 !important" (click)="onClick(dataItem)"
            [disabled]="dataItem.isStoppedProcessing || dataItem.status == 'Completed'">
            Stop
          </button>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="message" title="Message" [headerClass]="'headerCustomClass'" [width]="150"><ng-template
          kendoGridCellTemplate let-dataItem>
          <button kendoButton style="color: #fff; background-color: #193971 !important"
            (click)="openmessage(dataItem.message)">
            Show Message
          </button>
        </ng-template>
      </kendo-grid-column>


      <kendo-grid-column field="triggeredBy" title="Triggered By" [width]="220" [hidden]="true">
      </kendo-grid-column>
      <kendo-grid-column field="triggerType" title="Trigger Type" [width]="150" [hidden]="true">
      </kendo-grid-column>
      <kendo-grid-column field="clientId" title=" Client Id" [width]="120" [hidden]="true"></kendo-grid-column>
      <kendo-grid-column field="timeCodes" title="Time Codes" [width]="120" [hidden]="true"></kendo-grid-column>
      <kendo-grid-column field="docketProcessed" title="Docket Processed" [width]="120"
        [hidden]="true"></kendo-grid-column>
      <!--<kendo-grid-column field="status" title="Status" [width]="120" [hidden]="true"></kendo-grid-column>-->
      <kendo-grid-column field="trigerredOn" title="Triggered On" [width]="220" [hidden]="true">
      </kendo-grid-column>
      <kendo-grid-column field="createdBy" title="Created By" [width]="220" [hidden]="true">
      </kendo-grid-column>
      <kendo-grid-column field="createdDate" title="Created Date" [width]="220" [hidden]="true">
      </kendo-grid-column>
      <kendo-grid-column field="modifiedBy" title="Modified By" [width]="220" [hidden]="true">
      </kendo-grid-column>
      <kendo-grid-column field="modifiedDate" title="Modified Date" [width]="220" [hidden]="true">
      </kendo-grid-column>
      <kendo-grid-pdf fileName="OnDemand.pdf" [repeatHeaders]="true">
      </kendo-grid-pdf>
      <kendo-grid-excel fileName="OnDemand.xlsx"></kendo-grid-excel>
    </kendo-grid>

    <ng-container *ngIf="ismessageTextboxVisible">
      <kendo-dialog [title]="Title" *ngIf="openedmessage" (close)="close('cancel')">
        <div class="textareawrapOnDeMand">
          <kendo-textarea #textarer1 resizable="none" [(value)]="message" [readonly]="true"
            style="width: 800px; height: 101px; overflow: auto;"></kendo-textarea>
        </div>
      </kendo-dialog>
    </ng-container>
    <div kendoDialogContainer></div>
  </div>
  <br />
  <div *ngIf="addDemand">
    <form class="k-form" [formGroup]="ondemandForm">
      <div class="row">
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-formfield>
            <kendo-floatinglabel text="Select Client Name" [labelCssStyle]="{ 'font-size': '15px' }">
              <kendo-dropdownlist [filterable]="true" textField="name" valueField="id"
                (filterChange)="clientFilter($event)" [formControlName]="'clientName'"
                (selectionChange)="clientNameSelectionChange($event)" [data]="clientData" required></kendo-dropdownlist>
            </kendo-floatinglabel>
            <kendo-formerror>Client Name is required</kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-formfield>
            <kendo-floatinglabel text="Select Job Name" [labelCssStyle]="{ 'font-size': '15px' }">
              <kendo-dropdownlist placeholder="" [filterable]="true" textField="jobName" valueField="jobId"
                [data]="jobNameSource" [formControlName]="'jobName'" (selectionChange)="jobNameSelectionChange($event)"
                (filterChange)="jobNameFilter($event)" required></kendo-dropdownlist>
            </kendo-floatinglabel>
            <kendo-formerror>Job Name is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-formfield>
            <kendo-floatinglabel text="Select Trigger Type">
              <kendo-dropdownlist placeholder="" [filterable]="true" textField="type" valueField="id"
                (filterChange)="triggerTypeFilter($event)" (selectionChange)="triggerTypeSelectionChange($event)"
                [formControlName]="'triggerType'" [data]="triggerTypeData" #triggerType required>
              </kendo-dropdownlist>
            </kendo-floatinglabel>
            <kendo-formerror>Trigger Type required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col-md-3 col-sm-12 mb-4" *ngIf="selectedTriggerType == 'Docket'">
          <kendo-formfield>
            <kendo-floatinglabel text="Select Time Code To Be Tracked">
              <kendo-multiselect placeholder="" [checkboxes]="true" [autoClose]="false" [value]="selectedTimeCodes"
                [filterable]="true" textField="code" valueField="id" (filterChange)="timeCodeFilter($event)"
                [formControlName]="'timeCodeToBeTracked'" [data]="timeCodeIds">
              </kendo-multiselect>
            </kendo-floatinglabel>
            <kendo-formerror *ngIf="getFormControl['timeCodeToBeTracked']?.errors?.['required']">Time Code
              required</kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="col-md-3 col-sm-12 mb-4" *ngIf="!isRangeofClaims">
          <kendo-formfield>
            <kendo-floatinglabel text="Claim ID" [labelCssStyle]="{ 'font-size': '15px' }">
              <kendo-textbox [formControlName]="'startClaimId'" required>
              </kendo-textbox>
            </kendo-floatinglabel>
            <kendo-formerror *ngIf="getFormControl['startClaimId']?.errors?.['required']">claim id
              required</kendo-formerror>
            <kendo-formerror *ngIf="getFormControl['startClaimId']?.errors?.['pattern']">Please enter valid claim
              id</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col-md-3 col-sm-12 mb-4" *ngIf="isRangeofClaims">
          <kendo-formfield>
            <kendo-floatinglabel text="Start Claim ID" [labelCssStyle]="{ 'font-size': '15px' }">
              <kendo-textbox [formControlName]="'startClaimId'" required>
              </kendo-textbox>
            </kendo-floatinglabel>
            <kendo-formerror *ngIf="getFormControl['startClaimId']?.errors?.['required']">start claim id
              required</kendo-formerror>
            <kendo-formerror *ngIf="getFormControl['startClaimId']?.errors?.['pattern']">Please enter valid start claim
              id</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col-md-3 col-sm-12 mb-4" *ngIf="isRangeofClaims">
          <kendo-formfield>
            <kendo-floatinglabel text="End Claim ID" [labelCssStyle]="{ 'font-size': '15px' }">
              <kendo-textbox [formControlName]="'endClaimId'" required>
              </kendo-textbox>
            </kendo-floatinglabel>
            <kendo-formerror *ngIf="getFormControl['endClaimId']?.errors?.['required']">End claim id
              required</kendo-formerror>
            <kendo-formerror *ngIf="getFormControl['endClaimId']?.errors?.['pattern']">Please enter valid End claim
              id</kendo-formerror>
            <div *ngIf="getFormControl['endClaimId']?.errors?.['matchClaimId']">
              End claim id should be greater than start claim id
            </div>
          </kendo-formfield>
        </div>
        <div class="col-md-3 col-sm-12 mb-4">
          <div style="white-space: nowrap; width: 300px; display: inline-block">
            <kendo-formfield>
              <kendo-label class="k-checkbox-label" [for]="isRangeOfClaims"><input [formControlName]="'isRangeOfClaims'"
                  type="checkbox" (change)="onCheckboxChange($event)" [(ngModel)]="isRangeofClaims" #isRangeOfClaims
                  kendoCheckBox />
                Is Range of Claims
              </kendo-label>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-label class="k-checkbox-label" [for]="isNotificationRequiredForUnprocessedClaim"><input type="checkbox"
              [formControlName]="'isNotificationRequiredForUnprocessedClaim'" #isNotificationRequiredForUnprocessedClaim
              kendoCheckBox />
            Is Notification Required For Unprocessed Claims
          </kendo-label>
        </div>
      </div>

      <!-- <div class="row" class="add-btn">
      <button
        _ngcontent-whw-c5
        kendoButton
        [routerLink]="['/EmailConfiguration']"
        size="medium"
        rounded="medium"
        style="color: #fff; background-color: #193971 !important"
        (click)="exit()"
        *ngIf="addDemand"
      >
        Back
      </button>
    </div> -->
      <div class="row">
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-button _ngcontent-whw-c5 kendoButton size="medium" rounded="medium"
            style="color: #fff; background-color: #193971 !important" (click)="exit()" *ngIf="addDemand">
            Back
          </kendo-button>

          <kendo-button _ngcontent-whw-c5 style="color: #fff; background-color: #193971 !important"
            (click)="saveondemand()" [disabled]="!ondemandForm.valid">Save</kendo-button>
        </div>
      </div>
    </form>
    <div kendoDialogContainer></div>
  </div>
</div>