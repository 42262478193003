<app-contentheader headerText="History"></app-contentheader>
<div class="row" class="nav-item">
  <kendo-tabstrip>
    <kendo-tabstrip-tab title="Claim Watcher History" [selected]="true">
      <ng-template kendoTabContent>
        <app-claim-watcher-history></app-claim-watcher-history>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="SMS History">
      <ng-template kendoTabContent>
        <app-twilio-smsclaim-details></app-twilio-smsclaim-details>
      </ng-template>
    </kendo-tabstrip-tab>

  </kendo-tabstrip>
</div>