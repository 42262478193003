import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlGroup } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';
import { Sourcesystem } from 'src/app/models/config.model';
// import { Sourcesystem } from 'src/app/interfaces/sourcesystem';

import { Organizations } from 'src/app/models/config.model';
import { Trackedtables } from 'src/app/models/config.model';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { Timecode } from 'src/app/models/config.model';
import { Expensecode } from 'src/app/models/config.model';
import { Jobfrequency } from 'src/app/interfaces/jobfrequency';
import { Client } from 'src/app/models/config.model';

@Injectable({
  providedIn: 'root'
})
export class MasterdataService {

  constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }
  //  public getSourcesystem(id: string): Observable<Sourcesystem> {
  //   const url = this.apiService.sanitizeUrl(this.urls.prefixurl) + `adminconfiguration-read/v1/masterdata/allsourcesystem`;

  //    return this.http.get<Sourcesystem>(url);
  //  }
  //  public getClientList(): Observable<Sourcesystem[]> {
  //    return this.http.get<Sourcesystem[]>("url");
  //  }
  public getAllSourceSystem(): Observable<Sourcesystem[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/allsourcesystem`;
    console.log("url:");
    console.log(url);
    return this.apiService.get<Sourcesystem[]>(url);
  }

  public getAllOrganization(): Observable<Organizations[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/allorganizations`;
    return this.apiService.get<Organizations[]>(url);
  }

  public getAllTrackedTable(): Observable<Trackedtables[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/alltrackedtables`;
    return this.apiService.get<Trackedtables[]>(url);
  }

  public getAllTriggerType(): Observable<Triggertype[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/alltriggertype`;
    return this.apiService.get<Triggertype[]>(url);
  }

  public getAllTimecode(): Observable<Timecode[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/alltimecode`;
    return this.apiService.get<Timecode[]>(url);
  }

  public getAllExpensecode(): Observable<Expensecode[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/allexpensecode`;
    return this.apiService.get<Expensecode[]>(url);
  }

  public getAllJobfrequency(): Observable<Jobfrequency[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/alljobfrequency`;
    return this.apiService.get<Jobfrequency[]>(url);
  }

  public allclientforOnDemandJob(): Observable<Client[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/allclientforOnDemandJob`;
    return this.apiService.get<Client[]>(url);
  }

  public getAllClient(): Observable<Client[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/masterdata/allclient`;
    return this.apiService.get<Client[]>(url);
  }

  public postAllSourceSystem(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/CreateSourceSystem`;
    console.log("url:");
    console.log(url);
    return this.apiService.post<any>(url, data);
  }
  public putAllSourceSystem(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/UpdateSourceSystem`;
    console.log("url:");
    console.log(url);
    return this.apiService.put<any>(url, data);
  }

  public postAllOrganization(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/CreateOrganization`;
    console.log("url:");
    console.log(url);
    return this.apiService.post<any>(url, data);
  }
  public putAllOrganization(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/UpdateOrganization`;
    console.log("url:");
    console.log(url);
    return this.apiService.put<any>(url, data);
  }

  public postAllTrackedTable(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/CreateTrackedTables`;
    console.log("url:");
    console.log(url);
    return this.apiService.post<any>(url, data);
  }
  public putAllTrackedTable(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/UpdateTrackedTables`;
    console.log("url:");
    console.log(url);
    return this.apiService.put<any>(url, data);
  }

  public postAllTimecode(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/CreateTimeCode`;
    console.log("url:");
    console.log(url);
    return this.apiService.post<any>(url, data);
  }
  public putAllTimecode(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/UpdateTimeCode`;
    console.log("url:");
    console.log(url);
    return this.apiService.put<any>(url, data);
  }

  public postAllExpensecode(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/CreateExpenseCode`;
    console.log("url:");
    console.log(url);
    return this.apiService.post<any>(url, data);
  }
  public putAllExpensecode(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/UpdateExpenseCode`;
    console.log("url:");
    console.log(url);
    return this.apiService.put<any>(url, data);
  }
  public postAllClient(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/CreateClient`;
    console.log("url:");
    console.log(url);
    return this.apiService.post<any>(url, data);
  }
  public putAllClient(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/MasterData/UpdateClient`;
    console.log("url:");
    console.log(url);
    return this.apiService.put<any>(url, data);
  }

  public getAllConsumers(consumerId): Observable<Expensecode[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/${consumerId}/getConsumer`;
    return this.apiService.get<Expensecode[]>(url);
  }

  public postApiConsumer(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/createConsumer`;
    return this.apiService.post<any>(url, data);
  }


}

